import { Fragment } from "react"

import Button, { ButtonProps } from "@material-ui/core/Button"
import IconButton from "@material-ui/core/IconButton"
import Snackbar from "@material-ui/core/Snackbar"
import CloseIcon from "@material-ui/icons/Close"

/** Use for declarative implementation of toasts */
// ts-prune-ignore-next
export function Toast({
  message = "",
  actions,
  open,
  onClose,
  showCloseButton = true,
  ...props
}: {
  message: React.ReactNode
  actions?: React.ReactNode
  open: boolean
  onClose?: () => void
  showCloseButton?: boolean
  autoHideDuration?: number
}) {
  const handleClose = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return
    }
    onClose?.()
  }

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      open={open}
      autoHideDuration={props.autoHideDuration}
      onClose={handleClose}
      message={message}
      action={
        <Fragment>
          {actions}
          {showCloseButton && (
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          )}
        </Fragment>
      }
    />
  )
}

export function ToastActionButton({
  text,
  onClick,
  ...props
}: {
  text: string
  onClick: () => void
} & ButtonProps) {
  return (
    <Button color="secondary" size="small" onClick={onClick} {...props}>
      {text}
    </Button>
  )
}
